.Calendar {
  position: relative;
  padding-bottom: 75%;
  height: 500px;
  overflow: hidden;
}

.Calendar iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
