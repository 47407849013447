body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.declined {
  text-decoration: line-through;
  color: red !important;
}

.help {
  cursor: help;
}

.alert-danger a {
  color: #721c24;
}

.alert {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.legend {
  text-align: center;
}

.legend div {
  display: inline-block;
}

.legend-box {
  width: 125px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  margin: 0px 5px;
  padding: 5px;
  color: white;
}

.legend-box.events {
  background-color: #668cd9;
  border-color: #2952a3;
}

.legend-box.boarders {
  background-color: #7ec225;
  border-color: #2f6309;
}

.legend-box.practitioners {
  background-color: #8c66d9;
  border-color: #5229a3;
}

.legend-box.hatha {
  background-color: #737373;
  border-color: #333333;
}

.navbar a {
  text-decoration: none;
}

.navbar a:hover {
  text-decoration: underline;
}

.navbar-text {
  padding: 5px;
}

@media all and (max-width: 1600px) {
  .hide-1600 {
    display: none;
    width: 0;
    height: 0;
    opacity: 0;
    visibility: collapse;
  }
}

@media all and (max-width: 1000px) {
  .hide-1000 {
    display: none;
    width: 0;
    height: 0;
    opacity: 0;
    visibility: collapse;
  }
}

@media all and (max-width: 800px) {
  .hide-800 {
    display: none;
    width: 0;
    height: 0;
    opacity: 0;
    visibility: collapse;
  }
}

@media all and (max-width: 600px) {
  .hide-600 {
    display: none;
    width: 0;
    height: 0;
    opacity: 0;
    visibility: collapse;
  }
}

@media all and (min-width: 600px) {
  .show-600 {
    display: none;
    width: 0;
    height: 0;
    opacity: 0;
    visibility: collapse;
  }
}
